<template>
  <!--Section: Resume & Skills-->
  <section class="text-center text-white py-5">
    <div class="container mx-auto px-4 mb-4">
      <!-- Heading -->
      <h2 class="mb-5 header text-black fw-bold">
        Skills
      </h2>

      <div class="container__languages fw-medium">
        <!--Header-->
        <div class="container__text">
          <h4 class="row__header header pt-2 text-2xl">
            Languages
          </h4>
          <small class="row__small text text-xs fw-normal">Scripting, Programming and Markup Languages</small>
        </div>
        <!--End Header-->

        <div id="languages">
          <ul class="list-group">
            <li class="list-group-item d-flex justify-content-between align-items-center">
              HTML
              <span class="badge bg-primary rounded-pill">5+ Years</span>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center">
              CSS
              <span class="badge bg-primary rounded-pill">5+ Years</span>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center">
              JavaScript
              <span class="badge bg-primary rounded-pill">5+ Years</span>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center">
              Python
              <span class="badge bg-primary rounded-pill">3 Years</span>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center">
              Java
              <span class="badge bg-primary rounded-pill">1 Year</span>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center">
              C++
              <span class="badge bg-primary rounded-pill">1 Year</span>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center">
              TypeScript
              <span class="badge bg-primary rounded-pill">4 Months</span>
            </li>
          </ul>
        </div>
        <br />
      </div>
      <br />
      <div class="container__frameworks">
        <!--Header-->
        <div class="container__text">
          <h4 class="row__header header pt-2 text-2xl">Frameworks</h4>
          <small class="row__small text text-xs fw-normal">Styling, Front-end & Back-end</small>
        </div>
        <!--End Header-->

        <div id="frameworks">
          <ul class="list-group">
            <li class="list-group-item d-flex justify-content-between align-items-center">
              JQuery (JS)
              <span class="badge bg-primary rounded-pill">5+ Years</span>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center">
              Bootstrap (CSS)
              <span class="badge bg-primary rounded-pill">5+ Years</span>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center">
              Vue (JS/TS)
              <span class="badge bg-primary rounded-pill">4 Years</span>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center">
              Django (Python)
              <span class="badge bg-primary rounded-pill">3.5 Years</span>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center">
              Tailwind (CSS)
              <span class="badge bg-primary rounded-pill">3 Years</span>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center">
              Nuxt (JS)
              <span class="badge bg-primary rounded-pill">1 Year</span>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center">
              SASS/LESS (CSS)
              <span class="badge bg-primary rounded-pill">3 Months</span>
            </li>
          </ul>
        </div>
      </div>
      <br />
      <div class="container__devops">
        <!--Header-->
        <div class="container__text">
          <h4 class="row__header header pt-2 text-2xl">Developer Operations</h4>
          <small class="row__small text text-xs fw-normal">Servers, Paas, Etc</small>
        </div>
        <!--End Header-->

        <div id="devops">
          <ul class="list-group">
            <li class="list-group-item d-flex justify-content-between align-items-center">
              Github (Git)
              <span class="badge bg-primary rounded-pill">5+ Years</span>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center">
              Heroku
              <span class="badge bg-primary rounded-pill">4 Years</span>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center">
              Firebase
              <span class="badge bg-primary rounded-pill">3 Years</span>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center">
              AWS
              <span class="badge bg-primary rounded-pill">5 months</span>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center">
              Google Cloud
              <span class="badge bg-primary rounded-pill">5 months</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
  <!--End Section: Resume & Skills-->
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Resume Skills',
  setup() {
    return {};
  },
});
</script>
<style scoped>
section {
  background-color: #a7adba;
  border: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.container__row {
  justify-content: center;
}
.container__text {
  margin-top: 1rem;
  margin-bottom: 2rem;
}
.container__text small {
  color: black;
}
</style>
