<template>
  <header class="p-3 bg-dark text-white">
    <div class="container">
      <div class="d-flex flex-wrap align-items-center justify-content-between">
        <div class="mb-2 mb-lg-0 ">
          <router-link to="/" class="text-white text-decoration-none">
            <span id="name" class="fs-4">S. John</span>
          </router-link>
        </div>
        <div class="d-flex justify-content-end">
          <ul class="nav nav-pills text-end">
            <li class="nav-item">
              <router-link to="projects" class="text-white text-decoration-none nav-link">Projects</router-link>
            </li>
            <li class="nav-item">
              <router-link to="contact" class="text-white text-decoration-none nav-link">Contact</router-link>
            </li>
            <!--Github-->
            <li class="d-flex mr-3">
              <a
                class="footer-icons rounded-full h-10 w-10 py-2 px-4"
                href="https://www.github.com/kappalucky/"
                aria-label="Github Link"
                rel="noopener noreferrer"
                target="_blank"
              >
                <i class="fab fa-github"></i>
                <font-awesome-icon :icon="['fab', 'github']" class="p-1" size="lg" />
              </a>
            </li>
            <!--Linkedin-->
            <li class="d-flex mr-3">
              <a
                class="footer-icons rounded-full h-10 w-10 py-2 px-4"
                href="https://www.linkedin.com/in/kappalucky/"
                aria-label="Linkedin Link"
                rel="noopener noreferrer"
                target="_blank"
              >
                <font-awesome-icon :icon="['fab', 'linkedin-in']" class="p-1" size="lg" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </header>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'The Navbar',
  setup() {
    return {};
  },
});
</script>

<style scoped>
#name {
  font-family: 'Permanent Marker';
}
</style>
