<template>
  <section class="py-5">
    <div class="container mx-auto px-4 mb-4">
      <h2 class="mb-5 headers text-black fw-bold subpixel-antialiased text-center Capitalize">
        Projects
      </h2>
    </div>
    <project-card
      :technologies="['Vue 3', 'Bootstrap']"
      :textColorWhite="false"
      :reversed="false"
      source="//jsfiddle.net/Kappalucky/w8qd9nLp/1/embedded/html,css,result/"
      title="This Portfolio"
      :tasks="['SPA build out', 'Updating content as well as software']"
      color="white"
    >
      <template v-slot:description>
        <strong>WHEW!</strong> This portfolio has seen a lot of technologies under the hood. Went from regular degular
        Vue 2 to Nuxt to Vue 3. I had a great plan in mind when I moved over to Nuxt but figured that it wasn't worth
        the overhead so to TypeScript Vue 3 I went.
      </template>
      <template v-slot:button>
        <router-link to="/projects">
          <button class="btn btn-primary shadow-md fw-bold py-2 px-4 border-black rounded">
            View More
          </button>
        </router-link>
      </template>
    </project-card>
  </section>
</template>
<script>
import ProjectCard from './ProjectCard.vue';

export default {
  name: 'UpcomingWork',
  components: {
    ProjectCard,
  },
};
</script>
<style scoped></style>
