<template>
  <div class="toast-container position-absolute p-3 end-0" style="z-index: 1;">
    <!-- Success Toast -->
    <div
      class="toast align-items-center text-white bg-success border-0 show"
      role="alert"
      aria-live="assertive"
      aria-atomic="true"
      v-show="submitted"
    >
      <div class="d-flex">
        <div class="toast-body">
          Successfully sent message!
        </div>
        <button
          type="button"
          class="btn-close btn-close-white me-2 m-auto"
          data-bs-dismiss="toast"
          aria-label="Close"
        ></button>
      </div>
    </div>
    <!-- Error Toast -->
    <div
      class="toast align-items-center text-white bg-danger border-0 show"
      role="alert"
      aria-live="assertive"
      aria-atomic="true"
      v-show="error"
    >
      <div class="d-flex">
        <div class="toast-body">
          Sending Failed. Try sending a direct email instead.
        </div>
        <button
          type="button"
          class="btn-close btn-close-white me-2 m-auto"
          data-bs-dismiss="toast"
          aria-label="Close"
        ></button>
      </div>
    </div>
  </div>
  <div class="outer">
    <div class="middle">
      <div class="container mx-auto my-8">
        <form @submit.prevent="submit" class="px-8 pt-6 pb-8 mb-4 form-message">
          <h1 class="h3 mb-3 fw-normal">Contact form</h1>
          <p class="p-1">
            <small
              >Got a question? Concern? I'd love to hear from you. Send a message and I will respond as soon as
              possible</small
            >
          </p>

          <div class="my-1 form-floating">
            <input type="text" class="form-control" id="inputName" placeholder="Ben Smith" required v-model="name" />
            <label for="inputName">Name</label>
          </div>

          <div class="my-1 form-floating">
            <input
              type="email"
              class="form-control"
              id="inputEmail"
              placeholder="name@example.com"
              required
              v-model="email.value"
            />
            <label for="inputEmail">Email address</label>
          </div>

          <div class="my-1 form-floating">
            <textarea
              type="password"
              class="form-control"
              id="inputMessage"
              placeholder="Write here..."
              v-model="message.text"
              :maxlength="message.maxLength"
              name="textarea"
              required
              rows="3"
            ></textarea>
            <label for="inputMessage">Message</label>
            <small>
              <span class="counter">{{ message.text.length }} / {{ message.maxLength }}</span>
            </small>
          </div>
          <br />
          <button class="w-100 btn btn-lg btn-primary" type="submit">Send</button>
        </form>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref, reactive } from 'vue';
import { init, send } from 'emailjs-com';

export default defineComponent({
  setup() {
    //* Variables
    const emailRegExp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const name = ref('');
    const submitted = ref(false);
    const error = ref(false);

    //* Objects
    init('user_yBCzUos4jruM1I6EX5WQR');

    const email = reactive({
      value: '',
      valid: true,
    });

    const message = reactive({
      text: '',
      maxLength: 255,
    });

    const status = reactive({
      sending: false,
      message: '',
    });

    //* Methods
    function isEmail(value) {
      return emailRegExp.test(value);
    }

    function validate(type, value) {
      if (type === 'email') {
        email.valid = isEmail(value);
      }
    }

    const submit = async () => {
      validate('email', email.value);

      if (email.valid === true) {
        status.message = 'sending...';
        status.sending = true;
        await send('default_service', 'portfolio_contact', {
          name: name.value,
          email: email.value,
          message: message.text,
        }).then(
          () => {
            submitted.value = true;
            status.message = 'Sent';

            setTimeout(() => {
              submitted.value = false;
              status.sending = false;
              name.value = '';
              message.text = '';
              email.value = '';
              status.message = '';
            }, 3000);
          },
          () => {
            submitted.value = false;
            error.value = true;
            status.sending = false;

            setTimeout(() => {
              status.message = '';
              error.value = false;
            }, 3000);
          },
        );
      } else {
        submitted.value = false;
        status.sending = false;
      }
    };

    return {
      emailRegExp,
      name,
      email,
      message,
      submitted,
      error,
      status,
      isEmail,
      validate,
      submit,
    };
  },
});
</script>

<style>
.counter {
  justify-content: flex-end;
  display: flex;
}
.form-message {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
}
.outer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  margin-top: 5rem;
}
</style>
