<template>
  <the-navbar />
  <main id="main" role="main">
    <router-view />
  </main>
  <the-footer />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import TheNavbar from '@/components/TheNavbar.vue';
import TheFooter from '@/components/TheFooter.vue';

export default defineComponent({
  name: 'App',
  components: {
    TheNavbar,
    TheFooter,
  },
  setup() {
    return {};
  },
});
</script>

<style>
@import 'https://fonts.googleapis.com/css?family=Arima+Madurai:100|Nixie+One|Scope+One|Montserrat|Permanent+Marker&display=swap';
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#main {
  flex: 1;
  min-height: 100vh;
}
.titles {
  font-family: 'Permanent Marker';
}

.headers {
  font-family: 'Scope One';
}

.text {
  font-family: 'Montserrat', sans-serif;
}
</style>
