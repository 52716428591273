<template style="background-color: #343d46">
  <section class="mx-auto px-4 py-5 text-center">
    <div class="container mx-auto">
      <div class="flex justify-center pb-4">
        <div>
          <!-- Heading -->
          <h3 class="header fw-semibold text-xl text-white pt-5 mb-2">
            Introducing
          </h3>
          <!-- Description -->
          <h2 id="name" class="justify-right text-4xl text-white mx-auto mb-2">
            <span>Shaquille John</span>
          </h2>
          <small class="text text-white text-sm">Software Engineer / Full Stack Developer* </small>
          <hr />
          <small class="text text-white text-sm"
            >* : (...or whatever title conveys to your company that I can build front & back end systems 🙂)</small
          >
        </div>
      </div>
    </div>
  </section>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Home Header',
  setup() {
    return {};
  },
});
</script>

<style scoped>
section {
  background-color: #343d46;
}
.intro {
  height: 40vh;
  font-family: monospace;
}
#name {
  font-family: 'Permanent Marker';
}
</style>
