<template>
  <home-header />
  <about-me />
  <resume-skills />
  <contact-banner />
  <upcoming-work />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import HomeHeader from '@/components/HomeHeader.vue';
import AboutMe from '@/components/AboutMe.vue';
import ResumeSkills from '@/components/ResumeSkills.vue';
import ContactBanner from '@/components/ContactBanner.vue';
import UpcomingWork from '@/components/UpcomingWork.vue';

export default defineComponent({
  name: 'Home',
  components: {
    HomeHeader,
    AboutMe,
    ResumeSkills,
    ContactBanner,
    UpcomingWork,
  },
  setup() {
    return {};
  },
});
</script>
