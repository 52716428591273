<template>
  <section class="py-5">
    <div class="container contact-banner mb-4 mx-auto">
      <h1 class="contact-banner__header header text-xl fw-semibold">
        Ready to contact?
      </h1>
      <br />
      <div class="mx-auto w-75">
        <div class="d-flex justify-content-evenly text-center flex-column flex-sm-row">
          <router-link to="/contact" class="mx-auto p-2">
            <button class="btn btn-primary btn-lg">
              <span class="mx-auto">Contact Form</span>
            </button>
          </router-link>
          <a
            href="mailto:Shaquille.j.foster@gmail.com?subject=Contacting%20you%20from%20portfolio!"
            target="_blank"
            class="mx-auto p-2"
          >
            <button class="btn btn-primary btn-lg">
              <span class="mx-auto">Direct Email</span>
            </button>
          </a>
        </div>
      </div>
    </div>
  </section>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Contact Banner',
  setup() {},
});
</script>
<style scoped>
section {
  /*background-color: rgb(50, 91, 138);*/
  background-color: #343d46;
  color: white;
  text-align: center;
}
</style>
