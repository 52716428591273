<template>
  <section :style="{ backgroundColor: color }" class="border-black">
    <div class="container py-16 mx-auto">
      <div :class="[reversed ? 'flex-row-reverse' : '']" class="d-flex justify-content-evenly flex-wrap flex-md-nowrap">
        <div class="w-100 md:w-50 h-auto p-4 d-flex align-items-center">
          <iframe width="100%" height="300" :src="source" frameborder="0"></iframe>
        </div>
        <div :class="[textColorWhite ? 'text-white' : 'text-black']" class="w-100 md:w-1/2 h-auto p-4 text-start">
          <h2 class="fw-semibold header text-5xl">{{ title }}</h2>
          <hr />
          <div class="fw-bold text-sm tracking-tight">
            Technologies:
            <template v-for="technology in technologies" :key="technology.index">
              <span class="badge bg-primary rounded-pill text-xs capitalize m-1">{{ technology }}</span>
            </template>
          </div>
          <br />
          <div class="fw-semibold text-sm tracking-tight">
            <template v-if="tasks.length > 1">
              <span class="fw-bold">Tasks: </span>
            </template>
            <template v-else>
              <span class="fw-bold">Task: </span>
            </template>
            <ol class="list-group list-group-numbered list-group-flush">
              <template v-for="task in tasks" :key="task.index">
                <li class="list-group-item">{{ task }}</li>
              </template>
            </ol>
          </div>
          <br />
          <p class="fw-normal text-sm tracking-tight p-1">
            <slot name="description"></slot>
          </p>
          <div class="text-center text-white mt-2">
            <slot name="button"> </slot>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    title: {
      type: String,
      default: 'Project Title',
      required: true,
    },
    technologies: {
      type: Array,
      default() {
        return ['Javascript', 'HTML', 'CSS'];
      },
      required: true,
    },
    source: {
      type: String,
      required: true,
    },
    tasks: {
      type: Array,
      default() {
        return ['Writing & Reading code'];
      },
      required: false,
    },
    color: {
      type: String,
      default: '#343d46',
      required: true,
    },
    textColorWhite: {
      type: Boolean,
      default: true,
      required: true,
    },
    reversed: {
      type: Boolean,
      default: true,
      required: false,
    },
    url: {
      type: String,
      required: false,
    },
  },
  setup() {
    return {};
  },
});
</script>

<style scoped></style>
