<template>
  <footer class="mt-auto pt-3" style="background-color: #343d46;">
    <div class="container">
      <div class="d-flex justify-content-end items-center content-center content-around justify-end">
        <ul class="d-flex justify-content-between text-center">
          <!--Github-->
          <li class="mr-3">
            <a
              class="footer-icons rounded-full h-10 w-10 py-2 px-4"
              href="https://www.github.com/kappalucky/"
              aria-label="Github Link"
              rel="noopener noreferrer"
              target="_blank"
            >
              <i class="fab fa-github"></i>
              <font-awesome-icon :icon="['fab', 'github']" class="p-1" size="2x" />
            </a>
          </li>
          <!--Linkedin-->
          <li class="mr-3">
            <a
              class="footer-icons rounded-full h-10 w-10 py-2 px-4"
              href="https://www.linkedin.com/in/kappalucky/"
              aria-label="Linkedin Link"
              rel="noopener noreferrer"
              target="_blank"
            >
              <font-awesome-icon :icon="['fab', 'linkedin-in']" class="p-1" size="2x" />
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="d-flex justify-content-center align-center py-1" style="background-color: #040d16;">
      <span class="text-center text-white text-xs font-semibold">
        &copy; 2021 Shaquille John. All rights reserved.
      </span>
    </div>
  </footer>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'The Footer',
  setup() {},
});
</script>

<style>
.footer-icons {
  color: white;
}

.footer-icons:hover {
  color: black;
}
</style>
