<template>
  <!--Section: About Me-->
  <section class="text-center mt-5">
    <div class="container mx-auto px-4 mb-4 pb-5">
      <!-- Heading -->
      <h2 class="container__header mb-5 headers">About Me</h2>
      <!-- Grid row -->
      <div class="flex flex-row justify-center mb-1 md:mb-4">
        <!-- Grid column -->
        <div class="flex-col text text-black">
          <!-- Description -->
          <p class="mb-2">Hi! 👋🏾 <strong>Welcome to my development portfolio!</strong></p>
          <br />
          <p class="mb-4">
            Being that I update this beauty on a bi-yearly schedule, most of the original content
            about my drive and schooling is no longer a factor. So let us talk about what I've been
            working on!
            <small
              ><i
                >(P.S: If you are new to the site, this was once an <em>'essay'</em> about my
                journey to development.)</i
              ></small
            >
          </p>
          <p class="mb-2">
            From 2018 - 2020 I worked on three major projects...ok well maybe two major projects and
            one minor but three projects nonetheless. One was a transportation organization tracker
            (sorry, not open source), one was a sex tracker I made out of boredom while on vacation,
            which is currently being updated to its own API, and the other is up and coming software
            for my business. (Also, not open source.)
          </p>
          <br />
          <p class="mb-2">
            After all of this, I learned that I really like making REST API's so the next challenge
            is a Graph based API. Stay tuned!
          </p>
        </div>
      </div>
    </div>
  </section>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'About Me',
  setup() {
    return {};
  },
});
</script>

<style scoped>
section {
  background-color: white;
  border: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.container__header {
  font-weight: bold;
  font-size: 2rem;
}
img {
  width: 100px;
  height: 100px;
}
</style>
