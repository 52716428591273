<template>
  <section id="portfolio" class="pt-5">
    <div class="container mx-auto px-4 mb-4">
      <h2 class="mb-5 headers text-black fw-bold subpixel-antialiased text-center Capitalize">
        Projects
      </h2>
    </div>
    <project-card
      :technologies="['Vue 3', 'Bootstrap']"
      :textColorWhite="false"
      :reversed="false"
      source="//jsfiddle.net/Kappalucky/w8qd9nLp/1/embedded/html,css,result/"
      title="This Portfolio"
      :tasks="['SPA build out', 'Updated content as well as software']"
      color="white"
    >
      <template v-slot:description>
        <strong>WHEW!</strong> This portfolio has seen a lot of technologies under the hood. Went from regular degular
        Vue 2 to Nuxt to Vue 3. I had a great plan in mind when I moved over to Nuxt but figured that it wasn't worth
        the overhead so to TypeScript Vue 3 I went.
      </template>
    </project-card>
  </section>
  <section id="kbanking">
    <project-card
      :technologies="['Vue 2', 'TailwindCSS', 'Moment']"
      textColorWhite
      reversed
      source="//jsfiddle.net/Kappalucky/nepfso7k/3/embedded/js,html/dark/"
      title="Kbanking"
      :tasks="['Architected structure', 'Imported data from json file', 'Manipulated data & dates in Vue store']"
      color="black"
    >
      <template v-slot:description>
        I wasn't really given any form of direction for this project but I feel it came out pretty well. The header
        changes to a different quote on every load (I think there are only 3 quotes though so don't go wild). All of the
        data is sorted by type (Income, Expense, Etc) and totals are aggregated from each type
      </template>
      <template v-slot:button>
        <a href="https://github.com/Kappalucky/kbanking" target="_blank" class="mx-auto p-2">
          <button class="btn btn-primary btn-lg">
            <span class="mx-auto">Github</span>
          </button>
        </a>
        <a href="https://kbanking-66546.web.app/" target="_blank" class="mx-auto p-2">
          <button class="btn btn-primary btn-lg">
            <span class="mx-auto">Demo</span>
          </button>
        </a>
      </template>
    </project-card>
  </section>
  <section id="timesheet-front">
    <project-card
      :technologies="['Vue 2', 'TailwindCSS', 'Axios']"
      :textColorWhite="false"
      :reversed="false"
      source="//jsfiddle.net/Kappalucky/52rb8nsp/2/embedded/js,html/"
      title="Timesheet Application (Front-end)"
      :tasks="['Architected structure', 'Connected backend API']"
      color="white"
    >
      <template v-slot:description>
        I actually enjoyed creating this through and through. I was going to go for the push to Vue 3 but figured I
        should stick with what I know since there was a time limit to it being completed. Data is called and displayed
        from a Django based REST API. If on page load there is no data, that means the Heroku server turned off. Just
        refresh the page. Could I add a work around to solve this, Absolutely! Will I do it...maybe?
      </template>
      <template v-slot:button>
        <a href="https://github.com/Kappalucky/Timesheet-Application-GM-Frontend" target="_blank" class="mx-auto p-2">
          <button class="btn btn-primary btn-lg">
            <span class="mx-auto">Github</span>
          </button>
        </a>
        <a href="https://timesheet-b122d.web.app/" target="_blank" class="mx-auto p-2">
          <button class="btn btn-primary btn-lg">
            <span class="mx-auto">Demo</span>
          </button>
        </a>
      </template>
    </project-card>
  </section>
  <section id="timesheet-back">
    <project-card
      :technologies="['Django', 'Heroku']"
      textColorWhite
      reversed
      source="//jsfiddle.net/Kappalucky/52rb8nsp/2/embedded/js,html/dark/"
      title="Timesheet Application (Back-end)"
      :tasks="['Architected REST API', 'Wrote functions to import data from CSV file', 'Setup for Heroku']"
      color="#343d46"
    >
      <template v-slot:description>
        I normally write REST API's using a similar structure but this time I incorporated pagination since the CSV file
        was sort of massive. I wrote querysets to handle the manipulation of the data in the backend rather than doing
        it within the front. Had to write a few tests since I was working with numbers. You can clearly see when strings
        are incorrect but numbers can easily throw you for a loop.
      </template>
      <template v-slot:button>
        <a href="https://github.com/Kappalucky/Timesheet-Application-GM-Backend" target="_blank" class="mx-auto p-2">
          <button class="btn btn-primary btn-lg">
            <span class="mx-auto">Github</span>
          </button>
        </a>
        <a href="https://timesheet-gm.herokuapp.com/timesheets/" target="_blank" class="mx-auto p-2">
          <button class="btn btn-primary btn-lg">
            <span class="mx-auto">Demo</span>
          </button>
        </a>
      </template>
    </project-card>
  </section>
  <section id="kbikes">
    <project-card
      :technologies="['Vue 2', 'Tailwind']"
      :textColorWhite="false"
      :reversed="false"
      source="//jsfiddle.net/Kappalucky/ch4mptz7/2/embedded/js,html/"
      title="Kbikes"
      :tasks="['Architected SPA', 'JSON data manipulation & validation']"
      color="white"
    >
      <template v-slot:description>
        Created demo retail website with component routing based on url path. Built out checkout process with various
        validation checks, included randomized order numbering upon checkout completion.
      </template>
      <template v-slot:button>
        <a href="https://github.com/Kappalucky/bike" target="_blank" class="mx-auto p-2">
          <button class="btn btn-primary btn-lg">
            <span class="mx-auto">Github</span>
          </button>
        </a>
        <a href="https://bike-shop-358ca.web.app/" target="_blank" class="mx-auto p-2">
          <button class="btn btn-primary btn-lg">
            <span class="mx-auto">Demo</span>
          </button>
        </a>
      </template>
    </project-card>
  </section>
  <section id="kmerchants">
    <project-card
      :technologies="['Vue', 'TailWind CSS']"
      textColorWhite
      reversed
      source="//jsfiddle.net/Kappalucky/18c4xknj/1/embedded/js,html/dark/"
      title="Kmerchants"
      :tasks="['Architected SPA']"
      color="black"
    >
      <template v-slot:description>
        Wrote this for a job interview (Which I got by the way 👏🏾). I had to create a very simple merchant system
        essentially, it allowed for the creation of a merchant account and the linking of as many 'companies' as I want.
        Please do not actually add your Social Security Number; it doesn't save anything as there is no database but
        yea, don't be that guy/gal.
      </template>
      <template v-slot:button>
        <a href="https://github.com/Kappalucky/Kmerchants" target="_blank" class="mx-auto p-2">
          <button class="btn btn-primary btn-lg">
            <span class="mx-auto">Github</span>
          </button>
        </a>
        <a href="https://kmerchant-b57c0.web.app/" target="_blank" class="mx-auto p-2">
          <button class="btn btn-primary btn-lg">
            <span class="mx-auto">Demo</span>
          </button>
        </a>
      </template>
    </project-card>
  </section>
  <section id="sextivity" class="pb-5">
    <project-card
      :technologies="['Vue', 'Axios', 'Firebase', 'Bulma', 'Buefy', 'Moment']"
      :textColorWhite="false"
      :reversed="false"
      source="//jsfiddle.net/Kappalucky/u7abr81g/2/embedded/js,html,css/"
      title="Sextivity"
      :tasks="['Overall design and build', 'Styled to liking using Bulma']"
      color="white"
    >
      <template v-slot:description>
        As a period tracker tracks menstrual flows; this here tracks your sexual activity. The purpose is for the user
        to be able to always be in the know of their sexual life. Names, protection use, etc. As mentioned on home page,
        I made this out of boredom while on vacation, helped kill time during my <strong>16 hour</strong> total flight
        time...but it was a fun experience.
        <p class="pt-1">Login credentials are: Email: Demo@Demo.com Password: Demo123!</p>
      </template>
      <template v-slot:button>
        <a href="https://github.com/Kappalucky/sextivity" target="_blank" class="mx-auto p-2">
          <button class="btn btn-primary btn-lg">
            <span class="mx-auto">Github</span>
          </button>
        </a>
        <a href="https://sextivity-cef50.web.app/" target="_blank" class="mx-auto p-2">
          <button class="btn btn-primary btn-lg">
            <span class="mx-auto">Demo</span>
          </button>
        </a>
      </template>
    </project-card>
    <div class="container">
      <p class="text-black">If you made it this far, you should probably have noticed that I reaaaallly like Vue Js</p>
    </div>
  </section>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import ProjectCard from '../components/ProjectCard.vue';

export default defineComponent({
  components: {
    ProjectCard,
  },
  setup() {
    return {};
  },
});
</script>
